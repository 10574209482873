.App {
  text-align: center;
  width: 100%;
  overflow-x: hidden;
}

*{
  -ms-overflow-style: none;
}
::-webkit-scrollbar {
  display: none;
}

html, body, p {
  margin: 0;
  padding:0;

}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

/* .App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
} */

.logo-wrapper {
  margin-top: 5px; /* Adjust the value as per your desired spacing */
}

.main-section {
  display: flex;
  flex-direction: column;
  margin-top: 65px;
  padding: 5vh 0 0 0;
  min-height: calc(100vh - 65px - 5vh - 5vh - 4vh) !important;
}

.main-section-example {
  display: flex;
  flex-direction: column;
}


.map-section {
  padding: 5vh 0 0 0;
  min-height: calc(100vh - 65px - 5vh - 5vh - 4vh) !important;
  width: 60vw;
  margin: 65px auto 0px;

  @media (max-width: 768px) {
    width: 90vw;
  }
}

.map-component-section {
  min-height: calc(100vh - 65px - 5vh - 5vh - 4vh) !important;
  width: 60vw;
  margin: 65px auto 0px;

  @media (max-width: 768px) {
    width: 90vw;
  }
}

.map-container {
  width: 60vw;
  margin: 0 auto;

  @media (max-width: 768px) {
    width: 90vw;
  }
}

.map-component-container {
  width: 60vw;
  margin: 0 auto;

  @media (max-width: 768px) {
    width: 90vw;
  }
}

.logo-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin: 15vh auto;

  @media (max-width: 768px) {
    width: 80vw;
  }
}

.logo-img {
  width: 40vw;
  max-width: 100%;

  @media (max-width: 768px) {
    width: 80vw;
  }
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@font-face {
  font-family: 'Multicolore';
  src: url('./fonts/Multicolore.otf') format('opentype'),
       url('./fonts/Multicolore.ttf') format('truetype'),
       url('./fonts/Multicolore.woff') format('woff'),
       url('./fonts/Multicolore.woff2') format('woff2');
}

@font-face {
  font-family: 'Poppins';
  src: url('./fonts/Poppins-Light.eot'),
       url('./fonts/Poppins-Light.otf') format('opentype'),
       url('./fonts/Poppins-Light.ttf') format('truetype'),
       url('./fonts/Poppins-Light.woff') format('woff'),
       url('./fonts/Poppins-Light.woff2') format('woff2');
}

body {
  font-family: 'Poppins', sans-serif;
  font-size: 1 rem;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'Multicolore', sans-serif;
}

.MuiButton-root {
  font-family: 'Multicolore', serif;
}

a.home-link {
  color: inherit;
  text-decoration: none;
}

a.formulas-link {
  color: inherit;
  text-decoration: none;
}

a.three-link {
  color: inherit;
  text-decoration: none;
}

.three-link {
  margin: 20vw;
}

.three-link:hover {
  background-color: #ffffff !important;
  color: #002c52 !important;
}

.footer {
  background-color: #FFFFFF;
  color: #002C52;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 2vh 0vh;
  text-align: center !important;
  flex-shrink: 0;
  position: relative;
  height: 5vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.footer2 {
  background-color: #002c52;
  color: #FFFFFF;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 2vh 0vh;
  text-align: center !important;
  flex-shrink: 0;
  position: relative;
  height: 5vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

h1 {
  font-family: 'Multicolore', sans-serif;
  font-size: 3rem;
  font-weight: 700;
}

h2 {
  font-family: 'Multicolore', sans-serif;
  font-size: 2.5rem;
  font-weight: 600;
}

h3 {
  font-family: 'Multicolore', sans-serif;
  font-size: 2rem;
  /* Add more header styles as needed */
}

h4 {
  font-family: 'Multicolore', sans-serif;
  font-size: 1.75rem;
  /* Add more header styles as needed */
}

h5 {
  font-family: 'Multicolore', sans-serif;
  font-size: 1.5rem;
  /* Add more header styles as needed */
}

h6 {
  font-family: 'Multicolore', sans-serif;
  font-size: 1rem;
  margin: 0;
}

p {
  font-family: 'Poppins', sans-serif;
  font-size: 1rem;
}

.content {
  align-self: center;
  font-family: 'Poppins', sans-serif;
  font-size: 1.1rem;
  line-height: 1.6;
  margin: 20px auto;
  width: 60vw;
  text-align: left;
  flex: 1 0 auto;
  @media (max-width: 600px) { /* Adjust this breakpoint as needed */
    width: 90vw; /* Increase width for smaller screens */
  }
}



header {
  text-align: center;
  height: 65px;
}

.author-date-tags-section {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  width: 60vw;
  margin: 20px auto;

  @media (max-width: 600px) {
    display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  width: 90vw;
  margin: 20px auto;
  }
}

.author-date {
  order: 1;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  flex-direction: column;
}

.tags {
  order: 2;
  margin-left: auto;
  display: flex;
  align-items: flex-start;
  gap: 5px;
  /* Adjust the spacing between tags as needed */
  flex-wrap: wrap;
  max-width: 40vw;
  justify-content: flex-end;

  @media (max-width: 600px) {
    order: 2;
    margin-left: auto;
    display: flex;
    align-items: flex-start;
    gap: 5px;
    /* Adjust the spacing between tags as needed */
    flex-wrap: wrap;
    max-width: 60vw;
    justify-content: flex-end;
  }

}

.taglist {
  order: 2;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px; /* Adjust the spacing between tags as needed */
  flex-wrap: wrap;
}

.avatar img{
  max-width: 100%;
  max-height: 200px;
  object-fit: cover;

}

:root {
  --footer-height: 0;
}

body {
  margin: 0;
  padding-bottom: var(--footer-height);
}

.body-with-padding {
  padding-bottom: 500px;
}

.app-bar{
  height: 5vh;
}

.cards-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 20px;
  width: 60vw;
  margin: 0 auto;
}

.card-content {
  flex-grow: 1;
  /* -webkit-line-clamp: 2; */
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;

}

.card {
  height: 18vw;
  width: 18vw;
  justify-content: flex-start;
  align-items: baseline;
  display: flex;
  flex-direction: column;
}

.textField .MuiFilledInput-root{
    border-radius: 50px;
 }